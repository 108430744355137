import { Box } from '@mui/material';
import React, { useRef } from 'react';

export const Background: React.FC<{ url?: string }> = ({ url }) => {
  const ref = useRef<HTMLElement>(null);

  const offsetWidth = ref?.current?.offsetWidth;

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        height: '540px',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: '#494F64',
          backgroundImage: `url(${url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          ...(url && {
            filter: 'brightness(0.2)',
            '&::after': {
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              backdropFilter: 'blur(6px)',
            },
          }),
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: 0,
          pt: '540px',
          overflow: 'hidden',
          '&:after': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            content: '""',
            display: 'block',
            width: 0,
            height: 0,
            mt: '-200px',
            borderTop: '200px solid transparent',
            borderRight: `${offsetWidth}px solid #f5f5f5`,
          },
        }}
      />
    </Box>
  );
};
