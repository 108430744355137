import { FormAutocomplete } from 'components';
import i18nIsoCountries from 'i18n-iso-countries';
import { ReactNode, useMemo } from 'react';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { TFormDropdownCountry } from './types';

export const FormDropdownCountry = <
  TFieldValues extends FieldValues,
  FieldType extends PathValue<TFieldValues, Path<TFieldValues>> | undefined,
>({
  name,
  control,
  errors,
  languageCode = 'en',
  countryCode = 'GB',
  rules,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  unregister,
  enabled = true,
  maxLengthErrorMessage,
  minLengthErrorMessage,
  validateErrorMessage,
  requiredErrorMessage,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  ...selectProps
}: TFormDropdownCountry<TFieldValues> & { children?: ReactNode }) => {
  const countryList = useMemo(() => {
    const validLanguageCode = i18nIsoCountries.isValid(languageCode)
      ? languageCode
      : 'en';
    i18nIsoCountries.registerLocale(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require(`i18n-iso-countries/langs/${validLanguageCode}.json`),
    );
    const countryOptions = Object.entries(
      i18nIsoCountries.getNames(validLanguageCode, { select: 'official' }),
    )
      .map(([id, name]) => {
        if (name.includes('Taiwan')) {
          return {
            value: id,
            label: 'Taiwan',
          };
        }
        return {
          value: id,
          label: name,
        };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label, validLanguageCode);
      })
      .filter((item) => item.value !== countryCode);

    return [
      {
        value: countryCode,
        label: i18nIsoCountries.getName(countryCode, languageCode),
      },
    ].concat(countryOptions);
  }, [languageCode, countryCode]);

  if (!enabled) return null;

  return (
    <FormAutocomplete
      name={name}
      control={control}
      errors={errors}
      rules={{
        required: selectProps.required,
      }}
      defaultValue={
        (selectProps.defaultValue as FieldType) ?? ('' as FieldType)
      }
      label={selectProps.label?.toString() ?? ''}
      requiredErrorMessage={requiredErrorMessage}
      options={countryList}
    />
  );
};
