import {
  getLanguageFromCMS,
  getFullDateFormat,
} from '@guider-global/front-end-utils';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { IGoal, IProfile } from '@guider-global/shared-types';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { TimelineDot } from '@mui/lab';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { GuideAvatar } from 'components/Guide';
import { format, parseISO } from 'date-fns';
import { useMobileMediaQuery, useProfiles } from 'hooks';
import React from 'react';
import { useAppSelector } from 'store/hooks';

interface IGoalsDetails {
  goal: Partial<IGoal>;
  traineeProfile?: Partial<IProfile>;
}

export const GoalsDetails: React.FC<IGoalsDetails> = ({
  goal,
  traineeProfile,
}) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const { getProfiles } = useProfiles({ getSilently: false });
  let [profile] = getProfiles() as Partial<IProfile>[];

  if (traineeProfile) {
    profile = traineeProfile;
  }

  const goalCategories = useAppSelector((state) =>
    state.sanityOrganization.organization.goal_categories.goal_categories.map(
      (goalCategory) => ({
        label: goalCategory.goal_category_name,
        value: goalCategory.goal_category_slug.current,
      }),
    ),
  );

  const locale = getLanguageFromCMS(baseLanguage.language_code);

  const formattedDate = getFullDateFormat(baseLanguage.date_format);

  if (!goal?.createdAt) {
    return <></>;
  }

  const goalCategory = goalCategories.find(
    (goalCategory) => goalCategory.value === goal.categorySlug,
  )?.label;

  let status = baseLanguage.goals.goal_statuses.on_track;
  let palette: 'success' | 'warning' | 'error' | 'info' = 'info';
  let color = theme.palette[palette].main;

  if (goal.status === 'off_track') {
    status = baseLanguage.goals.goal_statuses.off_track;
    palette = 'warning';
    color = theme.palette[palette].main;
  }

  if (goal.status === 'on_hold') {
    status = baseLanguage.goals.goal_statuses.on_hold;
    palette = 'error';
    color = theme.palette[palette].main;
  }

  if (goal.status === 'completed') {
    status = baseLanguage.goals.goal_statuses.completed;
    palette = 'success';
    color = theme.palette[palette].main;
  }

  const goalDate = format(parseISO(goal.createdAt.toString()), formattedDate, {
    locale,
  });

  const renderDetails = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <CalendarToday sx={{ mr: 1, color: 'text.disabled', fontSize: 20 }} />
          <Typography variant="caption" color="text.secondary">
            {goalDate}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: isMobile ? color : 'inherit',
          }}
        >
          <TimelineDot color={palette} sx={{ mr: 1 }} />
          <Typography variant="subtitle2" component="p" fontWeight={400}>
            {status}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <>
      <Typography variant="body2" color="text.secondary" fontWeight={500}>
        {goal.objective !== '' && goal.objective !== undefined && goalCategory}
      </Typography>
      <Typography variant="h5">
        {goal.objective !== '' && goal.objective !== undefined
          ? goal.objective
          : goalCategory}
      </Typography>
      {isMobile && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          {renderDetails()}
        </Box>
      )}
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <GuideAvatar
            sx={{ mr: 1 }}
            profilePicture={profile?.picture}
            width={40}
            height={40}
          />
          <Box>
            <Typography
              variant="caption"
              component="p"
              color="text.secondary"
              fontWeight={400}
            >
              {baseLanguage.goals.view_goal.set_by_label}
            </Typography>
            <Typography variant="body2">{profile?.displayName}</Typography>
          </Box>
        </Box>
        {!isMobile && renderDetails()}
      </Box>
      {goal.outcomes !== '' && goal.outcomes !== undefined && (
        <>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <Typography
            variant="h6"
            component="p"
            sx={{ fontSize: '1.125rem !important' }}
          >
            {baseLanguage.goals.view_goal.outcomes_label}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight={400}
          >
            {goal.outcomes}
          </Typography>
        </>
      )}
      {goal.rationale !== '' && goal.rationale !== undefined && (
        <>
          <Divider sx={{ mt: 2, mb: 3 }} />
          <Typography
            variant="h6"
            component="p"
            sx={{ fontSize: '1.125rem !important' }}
          >
            {baseLanguage.goals.view_goal.benefits_label}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight={400}
          >
            {goal.rationale}
          </Typography>
        </>
      )}
    </>
  );
};
