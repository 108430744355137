import { Avatar, CircularProgress, SxProps, Theme } from '@mui/material';
import {
  buildSanityImageUrl,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { usePicture } from '@guider-global/azure-storage-hooks';

export interface IGuideAvatarProps {
  profilePicture?: string;
  userPicture?: string;
  width?: number;
  height?: number;
  sx?: SxProps<Theme>;
}
export function GuideAvatar({
  profilePicture,
  userPicture,
  width,
  height,
  sx = {},
}: Readonly<IGuideAvatarProps>) {
  const { loading, getImage } = usePicture({});
  const { settings } = useSanitySettings({});

  const profileImage =
    !!profilePicture && !loading ? getImage(profilePicture) : undefined;
  const usersImage =
    userPicture ??
    buildSanityImageUrl({
      source: settings?.static_media.relationships.default_user_avatar ?? '',
    });
  const image = profileImage ?? usersImage;

  const avatarProps = {
    ...(!loading && {
      src: image,
    }),
  };

  return (
    <Avatar
      variant="rounded"
      sx={{
        aspectRatio: '1',
        width: width ?? '100%',
        height: height ?? 'auto',
        backgroundColor: 'white',
        ...sx,
      }}
      {...avatarProps}
    >
      <CircularProgress size={width && width / 2} color="secondary" />
    </Avatar>
  );
}
