import { Box, Skeleton } from '@mui/material';
import { SecondaryPanelContent } from '../SecondaryPanelContent';

export function SecondaryPanelContentSkeleton() {
  return (
    <SecondaryPanelContent>
      <Box
        sx={{
          height: '100%',
          padding: '8px 24px 24px 24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{
            height: 'calc((100% - 32px) * 0.3)',
            minHeight: { xs: '60px', md: '30px' },
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            height: 'calc((100% - 32px) * 0.5)',
            minHeight: { xs: '100px', md: '50px' },
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            height: 'calc((100% - 32px) * 0.2)',
            minHeight: { xs: '40px', md: '20px' },
          }}
        />
      </Box>
    </SecondaryPanelContent>
  );
}
