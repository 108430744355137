// external
import {
  Box,
  Button,
  Card,
  Checkbox,
  Typography,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import React from 'react';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { TimelineDot } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

// internal
import { IGoal } from '@guider-global/shared-types';
import { useMixpanelEvents, useMobileMediaQuery } from 'hooks';
import { hexToRGB } from 'utils';

// Hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useAppSelector } from 'store/hooks';

// store
import {
  getFullDateFormat,
  getLanguageFromCMS,
} from '@guider-global/front-end-utils';
import { MDIcons, SanityIcon } from 'components';
import { selectOrganizationGoalCategories } from 'store/slices/sanityOrganizationSlice';

interface IGoalsItemProps {
  goal: Partial<IGoal>;
  sx?: SxProps<Theme>;
  viewPath?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  linkGoalVariant?: boolean;
  handleLinkGoal?: VoidFunction;
}

type IGoalsItemPropsConditional =
  | { selectable?: undefined; selected?: never }
  | { selectable?: true; selected: boolean };

export const GoalsItem: React.FC<
  IGoalsItemProps & IGoalsItemPropsConditional
> = ({
  goal,
  sx = {},
  viewPath,
  selectable,
  selected,
  onClick,
  linkGoalVariant = false,
  handleLinkGoal,
}) => {
  const { trackScreenTimeAndClick } = useMixpanelEvents();
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const goalCategories = useAppSelector(selectOrganizationGoalCategories);
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const navigate = useNavigate();

  const locale = getLanguageFromCMS(baseLanguage.language_code);

  const formattedDate = getFullDateFormat(baseLanguage.date_format);

  if (!goal.createdAt) {
    return <></>;
  }

  const viewPathToUse = viewPath || `/goals/${goal.id}`;

  let status = baseLanguage.goals.goal_statuses.on_track;
  let palette: 'success' | 'warning' | 'error' | 'info' = 'info';
  let color = theme.palette[palette].main;

  if (goal.status === 'off_track') {
    status = baseLanguage.goals.goal_statuses.off_track;
    palette = 'warning';
    color = theme.palette[palette].main;
  }

  if (goal.status === 'on_hold') {
    status = baseLanguage.goals.goal_statuses.on_hold;
    palette = 'error';
    color = theme.palette[palette].main;
  }

  if (goal.status === 'completed') {
    status = baseLanguage.goals.goal_statuses.completed;
    palette = 'success';
    color = theme.palette[palette].main;
  }

  const goalCategory = goalCategories.find(
    (goalCategory) =>
      goalCategory.goal_category_slug.current === goal.categorySlug,
  );

  const goalDate = format(parseISO(goal.createdAt.toString()), formattedDate, {
    locale,
  });

  const goalCategoryIconName = goalCategories.find(
    (goalCategory) =>
      goalCategory.goal_category_slug.current === goal.categorySlug,
  )?.goal_category_icon?.name;

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        ...(selectable && { cursor: 'pointer' }),
        ...sx,
      }}
      variant="outlined"
      onClick={onClick}
      data-cy={`components_Goals_GoalsItem_goalDisplay_${goal.objective}`}
    >
      {!isMobile && !selectable && (
        <Box
          sx={{
            width: '58px',
            minWidth: '58px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `rgba(${hexToRGB(color)}, 0.08)`,
            color: { color },
            fontSize: '30px',
          }}
        >
          <SanityIcon name={goalCategoryIconName as MDIcons} />
        </Box>
      )}
      {selectable && (
        <Box
          sx={{
            width: '58px',
            minWidth: '58px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `rgba(${hexToRGB(theme.palette.info.main)}, 0.08)`,
          }}
        >
          <Checkbox checked={selected} color="info" />
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1.5,
          px: 2,
          ...(selected && {
            backgroundColor: `rgba(${hexToRGB(theme.palette.info.main)}, 0.08)`,
          }),
          position: 'relative',
        }}
      >
        <Box sx={{ width: '70%' }}>
          <Typography variant="caption">
            {goal.objective
              ? goalCategory?.goal_category_name ??
                goalCategory?.goal_category_slug.toString()
              : undefined}
          </Typography>
          <Typography sx={{ my: 0.5 }} variant="body1">
            {goal.objective && goal.objective !== ''
              ? goal.objective
              : goalCategory?.goal_category_name ??
                goalCategory?.goal_category_slug.toString()}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <CalendarToday
              sx={{ mr: 1, color: 'text.disabled', fontSize: 20 }}
            />
            {isMobile ? (
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant="caption"
                  sx={{ mr: 1, whiteSpace: 'nowrap' }}
                >
                  {goalDate} ·
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color, whiteSpace: 'nowrap' }}
                >
                  {status}
                </Typography>
              </Box>
            ) : (
              <Typography variant="caption">{goalDate}</Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: isMobile ? 'auto' : '250px',
            minWidth: isMobile ? 'auto' : '250px',
            height: isMobile ? '100%' : 'auto',
            pl: 2,
            ...(isMobile && {
              position: 'absolute',
              top: 1.5,
              right: 0,
            }),
          }}
        >
          {!isMobile && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                color: isMobile ? color : 'inherit',
              }}
            >
              <TimelineDot color={palette} sx={{ mr: 1 }} />
              {status}
            </Box>
          )}
          {linkGoalVariant ? (
            <Button
              onClick={handleLinkGoal}
              variant="outlined"
              size="small"
              color="info"
              sx={{
                ml: 2,
                whiteSpace: 'nowrap',
                fontWeight: 600,
                color: theme.palette.text.secondary,
                borderColor: theme.palette.divider,
                mr: 1.5,
                mt: 1.5,
              }}
            >
              {baseLanguage.globals.goals.link_goal_button_label}
            </Button>
          ) : (
            <Button
              data-cy={`components_Goals_GoalsItem_view-goal-button_${goal.objective}`}
              onClick={() => {
                trackScreenTimeAndClick('Goal - Goal viewed');
                navigate(viewPathToUse);
              }}
              variant="outlined"
              size="small"
              color="info"
              sx={{
                ml: 2,
                whiteSpace: 'nowrap',
                fontWeight: 600,
                color: theme.palette.text.secondary,
                borderColor: theme.palette.divider,
                mr: 1.5,
                mt: 1.5,
              }}
            >
              {baseLanguage.globals.goals.view_goal_button_label ?? 'View Goal'}
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};
