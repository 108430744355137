import { PropsWithChildren } from 'react';
import { Card } from '@guider-global/ui';

export function SecondaryPanelContent({ children }: PropsWithChildren) {
  return (
    <Card
      sx={{
        width: '100%',
        pt: 2,
        borderRadius: { xs: 0, md: '8px' },
        flex: 1,
        boxShadow: '0px 8px 16px 0px #00000026',
      }}
    >
      {children}
    </Card>
  );
}
