import { SecondaryPanel } from '../SecondaryPanel';
import { SecondaryPanelTitleSkeleton } from '../SecondaryPanelTitleSkeleton';
import { SecondaryPanelContentSkeleton } from '../SecondaryPanelContentSkeleton';

export function SecondaryPanelSkeleton() {
  return (
    <SecondaryPanel>
      <SecondaryPanelTitleSkeleton />
      <SecondaryPanelContentSkeleton />
    </SecondaryPanel>
  );
}
