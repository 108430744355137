// external
import { ThemeProvider } from '@mui/material/styles';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Box, createTheme, Theme } from '@mui/material';
import { Auth0Provider } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
import { IntercomProvider } from 'react-use-intercom';

// internal
import { browserHistory } from 'utils';

// components
import { AppContainer, GenericError } from 'components';
import { useEffect, useMemo, useRef, useState } from 'react';

// Hooks
import {
  useSanitySettings,
  buildSanityFileUrl,
  buildSanityImageUrl,
} from '@guider-global/sanity-hooks';
import { theme as defaultTheme } from 'styles';
import { ThemeContext } from 'context/theme';
import { FluentThemeProvider } from '@azure/communication-react';

export const App = () => {
  const [theme, setTheme] = useState<Theme>(createTheme(defaultTheme));
  const themeValue = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme],
  );

  const { settings, loading } = useSanitySettings({ getSilently: true });

  // Refs
  const appContainer = useRef<HTMLDivElement>();
  const {
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_BASE_API_URL,
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_MIXPANEL_PROJECT_TOKEN,
    REACT_APP_MIXPANEL_ALLOWED_HOSTS,
    REACT_APP_INTERCOM_APP_ID,
    NODE_ENV,
    REACT_APP_SCOPES,
  } = process.env;

  const isNotProduction = useMemo(() => NODE_ENV !== 'production', [NODE_ENV]);

  const appStyles = {
    minHeight: '100vh',
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
    '*': {
      boxSizing: 'border-box',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexGrow: 1,
    },
  };

  useEffect(() => {
    const envToken = REACT_APP_MIXPANEL_PROJECT_TOKEN ?? 'DUMMY_TOKEN';
    const allowedHosts = REACT_APP_MIXPANEL_ALLOWED_HOSTS?.split(',') ?? [];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_subdomain, domain, _topLevelDomain] =
      window.location.host.split('.');
    const isProduction = domain === 'guider';
    const isIncludedInHost = (value: string) =>
      window.location.host.includes(value);
    const isAllowedHost = allowedHosts.filter(Boolean).some(isIncludedInHost);

    const shouldEnableMixpanel = isProduction || isAllowedHost;
    const token = shouldEnableMixpanel ? envToken : 'INVALID_TOKEN';

    mixpanel.init(token, {
      debug: isNotProduction,
    });
    mixpanel.track('Visited');
  }, [
    REACT_APP_MIXPANEL_PROJECT_TOKEN,
    REACT_APP_MIXPANEL_ALLOWED_HOSTS,
    isNotProduction,
  ]);

  useEffect(() => {
    const container = appContainer.current;
    if (!container) return;
    if (!window) return;

    const resize = () => {
      const innerHeight = window.innerHeight;
      const containerStyle = container.style;
      containerStyle.minHeight = `${innerHeight}px`;
    };
    resize();
    window.addEventListener('resize', resize);

    return () => {
      container.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <>
      {settings && !loading && (
        <Helmet>
          <meta
            name="application-name"
            content={settings.metadata.generic.application_name}
          />
          <title>{settings.metadata.generic.title}</title>
          <meta
            name="description"
            content={settings.metadata.generic.description}
          />
          <meta
            name="theme-color"
            content={
              theme?.palette.primary.main ||
              settings.metadata.generic.theme_color_fallback.hex
            }
          />
          <link
            rel="shortcut icon"
            href={buildSanityFileUrl({
              source: settings.metadata.generic.favicon.asset,
            })}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={buildSanityImageUrl({
              source: settings.metadata.generic.favicon_32x32,
            })}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={buildSanityImageUrl({
              source: settings.metadata.generic.favicon_16x16,
            })}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={settings.metadata.open_graph.title}
          />
          <meta
            property="og:url"
            content={window.location.origin || 'https://guider.app/'}
          />
          <meta
            property="og:image"
            content={buildSanityImageUrl({
              source: settings.metadata.open_graph.image,
            })}
          />
          <meta
            property="og:description"
            content={settings.metadata.open_graph.description}
          />
          <meta
            name="apple-mobile-web-app-title"
            content={settings.metadata.apple.mobile_web_app_title}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={buildSanityImageUrl({
              source: settings.metadata.apple.apple_touch_icon,
            })}
          />
          <link
            rel="mask-icon"
            href={buildSanityImageUrl({
              source: settings.metadata.apple.mask_icon,
            })}
            color={settings.metadata.apple.mask_icon_color.hex}
          />
          <meta
            name="apple-mask-icon-color"
            content={settings.metadata.apple.mask_icon_color.hex}
          />
          <meta
            name="msapplication-TileColor"
            content={settings.metadata.microsoft.ms_application_tile_color.hex}
          />
        </Helmet>
      )}
      {REACT_APP_AUTH0_CLIENT_ID && REACT_APP_AUTH0_DOMAIN ? (
        <FluentThemeProvider>
          <Box sx={appStyles} ref={appContainer}>
            <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID ?? ''}>
              <Auth0Provider
                authorizationParams={{
                  redirect_uri: `${window.location.origin}/login`,
                  scope: REACT_APP_SCOPES,
                  audience: REACT_APP_AUTH0_AUDIENCE ?? REACT_APP_BASE_API_URL,
                }}
                domain={REACT_APP_AUTH0_DOMAIN}
                clientId={REACT_APP_AUTH0_CLIENT_ID}
                cacheLocation="localstorage"
                useRefreshTokens
              >
                <HistoryRouter
                  /*
                TODO Replace with React Router v6 implementation
                // @ts-ignore */
                  history={browserHistory}
                >
                  <ThemeContext.Provider value={themeValue}>
                    <ThemeProvider theme={theme}>
                      <AppContainer />
                    </ThemeProvider>
                  </ThemeContext.Provider>
                </HistoryRouter>
              </Auth0Provider>
            </IntercomProvider>
          </Box>
        </FluentThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <GenericError
            code="CONFIG-AUTH"
            message="Configuration data for the authentication solution is not available or invalid."
          />
        </ThemeProvider>
      )}
    </>
  );
};
