import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export function SecondaryPanel({ children }: PropsWithChildren) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: {
          xs: '100%',
          md: '30%',
        },
        flex: '1 1 0',
      }}
    >
      {children}
    </Box>
  );
}
