import { BaseLoadingButtonProps, BaseLoadingButton } from '@guider-global/ui';

import { FC, PropsWithChildren, ReactNode } from 'react';

export interface SSOloginButtonProps {
  'data-cy': string;
  icon: ReactNode;
  onClick: BaseLoadingButtonProps['onClick'];
  loading?: boolean;
}

export const SSOloginButton: FC<PropsWithChildren<SSOloginButtonProps>> = ({
  'data-cy': dataCy,
  icon,
  onClick,
  loading,
  children,
}) => {
  return (
    <BaseLoadingButton
      color="info"
      loading={loading}
      startIcon={icon}
      data-cy={`${dataCy}-SSO-login-Button`}
      onClick={onClick}
      variant="outlined"
      fullWidth
      sx={{ justifyContent: 'flex-start' }}
    >
      {children}
    </BaseLoadingButton>
  );
};
