import { Text } from '@guider-global/ui';

export interface SecondaryPanelTitleProps {
  text: string;
}

export function SecondaryPanelTitle({ text }: SecondaryPanelTitleProps) {
  return (
    <Text
      variant="h4"
      text={text}
      align="center"
      color={{ xs: undefined, md: '#FFFFFF' }}
      sx={{
        m: 0,
        mb: 1,
        ml: { xs: 2, md: 0 },
      }}
    />
  );
}
