import React, { ReactNode } from 'react';
// external
import { Backdrop, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Internal
import { useAuth } from '@guider-global/auth-hooks';
import { getOrigin } from '@guider-global/front-end-utils';

// components
import { ErrorComponent, Footer } from 'components';
import { useMixpanelEvents } from 'hooks';

export interface IErrorProps {
  code?: string;
  message?: string;
  action?: { onclick: () => void; label: string; icon?: ReactNode };
  onClickLogin?: () => void;
  onClickLogout?: () => void;
  authorized?: boolean;
}

export interface IGenericErrorProps
  extends Omit<IErrorProps, 'onClickLogin' | 'onClickLogout' | 'authorized'> {
  visible?: boolean;
  overlay?: boolean;
}

export const GenericError: React.FC<IGenericErrorProps> = ({
  code = 'unexpectedError',
  message = 'An unknown error occurred',
  visible = true,
  overlay = false,
  action,
}) => {
  const { resetUserIdentity } = useMixpanelEvents();
  const { isAuthorized, logout } = useAuth({ waitForAuthentication: true });

  // Route
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: getOrigin() } });
    resetUserIdentity();
  };

  if (visible) {
    if (overlay) {
      return (
        <Backdrop
          open={visible}
          appear={false}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <ErrorContainer>
            <ErrorComponent
              message={message}
              code={code}
              action={action}
              onClickLogin={handleLogin}
              onClickLogout={handleLogout}
              authorized={isAuthorized}
            />
          </ErrorContainer>
        </Backdrop>
      );
    } else {
      return (
        <ErrorContainer>
          <ErrorComponent
            message={message}
            code={code}
            action={action}
            onClickLogin={handleLogin}
            onClickLogout={handleLogout}
            authorized={isAuthorized}
          />
        </ErrorContainer>
      );
    }
  }
  return null;
};

const ErrorContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        boxSizing: 'border-box',
        backgroundColor: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 100,
      }}
    >
      {children}
      <Footer />
    </Box>
  );
};
