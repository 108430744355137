import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { getSubDomain } from '@guider-global/front-end-utils';
import {
  SessionStorageKeys,
  useProfiles,
  useSessionStorageTyped,
  useUsers,
} from 'hooks';

import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

import { useAuth } from '@guider-global/auth-hooks';
import { useSanityOrganization } from '@guider-global/sanity-hooks';
import { URLQueryParams } from '@guider-global/shared-types';
import { LoadingElement } from 'components';
import { getIncompleteProfileFields } from 'utils';
import { datadogLogs } from '@guider-global/datadog';

export interface AuthenticatedRouteWrapperProps {
  profilesGetSilently?: boolean;
  usersGetSilently?: boolean;
  element: ReactNode;
}
export const AuthenticatedWrapper: FC<AuthenticatedRouteWrapperProps> = ({
  profilesGetSilently = true,
  usersGetSilently = true,
  element,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // Local State
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  // Auth
  const { isLoading, isAuthenticated } = useAuth({});
  // Redux
  const dispatch = useAppDispatch();
  //Intercom
  const { REACT_APP_VERSION } = process.env;
  const { update: updateIntercom } = useIntercom();
  //Utils
  const organizationSlug = getSubDomain();

  // Hooks
  // - useSessionStorage
  const [sessionRedirect] = useSessionStorageTyped<string>(
    SessionStorageKeys.REGISTER_REDIRECT,
  );
  // -Users
  const {
    users: getUsers,
    getErrorsUsers,
    isErrorUsers,
    isSuccessUsers,
  } = useUsers({ getSilently: usersGetSilently });
  const errorSanityUsers = isErrorUsers();
  const successSanityUsers = isSuccessUsers();
  const users = getUsers();
  const isUsers = users.length > 0;
  const user = isUsers ? users[0] : undefined;

  // -Profiles
  const {
    getErrorsProfiles,
    isErrorProfiles,
    isSuccessProfiles: getIsSuccessProfiles,
    getProfiles,
  } = useProfiles({ getSilently: profilesGetSilently });
  const isSuccessProfiles = getIsSuccessProfiles();
  const errorSanityProfiles = isErrorProfiles();
  const profiles = getProfiles();
  const [profile] = profiles;

  // - Sanity organization
  const { getOrganization } = useSanityOrganization({
    getSilently: false,
    organizationSlug,
  });
  const sanityOrganization = getOrganization();
  const additionalAgreements =
    sanityOrganization.white_label.additional_agreements;

  //  Events
  const incompleteProfileFields = useMemo(() => {
    if (!profile) return [];
    return getIncompleteProfileFields({
      profile,
      sanityOrganization,
      organizationAgreements: additionalAgreements,
    });
  }, [additionalAgreements, profile, sanityOrganization]);

  useEffect(() => {
    if (isError) return;
    const currentUrl = new URL(window.location.href);
    const navigateToDifferentPath = (pathName: string) => {
      if (currentUrl.pathname !== pathName) {
        navigate(pathName);
      }
    };
    setLoading(true);
    if (isLoading) return;
    if (!isAuthenticated) {
      const locationPathname = location.pathname;
      const isGuideOrTraineeProgramRegistrationPage =
        locationPathname.endsWith('/join/guide') ||
        locationPathname.endsWith('/join/trainee');
      const navigationAddress = isGuideOrTraineeProgramRegistrationPage
        ? '/register/account'
        : '/login';
      const to = locationPathname
        ? `${navigationAddress}?${URLQueryParams.REDIRECT}=${encodeURIComponent(
            locationPathname,
          )}`
        : navigationAddress;
      navigate(to);
      return;
    }
    if (!isSuccessProfiles || !successSanityUsers) return;
    updateIntercom({
      name: profile?.displayName,
      email: user?.email,
      userId: profile?.userId,
      createdAt: user?.createdAt?.toString(),
      customAttributes: {
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        organizationSlug,
        appVersion: REACT_APP_VERSION,
      },
    });
    if (!profile || !profile.isOnboarded) {
      navigateToDifferentPath('/register');
      setLoading(false);
      return;
    }
    if (incompleteProfileFields.length > 0) {
      navigateToDifferentPath('/complete-profile');
      setLoading(false);
      return;
    }
    if (sessionRedirect) {
      sessionStorage.removeItem(SessionStorageKeys.REGISTER_REDIRECT);
      window.history.pushState({}, '', `${origin}/programs`);
      try {
        const fullUrl = new URL(sessionRedirect);
        if (fullUrl) {
          if (currentUrl.href !== fullUrl.href) {
            window.location.href = fullUrl.href;
          }
        }
        setLoading(false);
        return;
      } catch (_error) {
        navigateToDifferentPath(sessionRedirect);
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }, [
    REACT_APP_VERSION,
    incompleteProfileFields.length,
    isAuthenticated,
    isError,
    isLoading,
    isSuccessProfiles,
    location.pathname,
    navigate,
    organizationSlug,
    profile,
    sessionRedirect,
    successSanityUsers,
    updateIntercom,
    user?.createdAt,
    user?.email,
  ]);

  useEffect(() => {
    if (isError) return;

    const handleShowAppError = (errorCode?: string, errorMessage?: string) => {
      console.error('error', { errorCode, errorMessage });
      datadogLogs.logger.error('authenticateWrapper:', {
        errorCode,
        errorMessage,
      });
      setIsError(true);
      dispatch(
        showAppAlert({
          severity: 'error',
          message: errorMessage,
          timeout: 10000,
        }),
      );
      setLoading(false);
    };

    if (errorSanityProfiles) {
      const profileErrors = getErrorsProfiles();
      const errorCodes = `${profileErrors.map((error) => error.code)}`;
      const errorMessages = `${profileErrors.map((error) => error.message)}`;
      handleShowAppError(errorCodes, errorMessages);
    }
    if (errorSanityUsers) {
      const usersErrors = getErrorsUsers();
      const errorCodes = `${usersErrors.map((error) => error.code)}`;
      const errorMessages = `${usersErrors.map((error) => error.message)}`;
      handleShowAppError(errorCodes, errorMessages);
    }
  }, [
    dispatch,
    errorSanityProfiles,
    errorSanityUsers,
    getErrorsProfiles,
    getErrorsUsers,
    isError,
  ]);

  if (loading) return <LoadingElement />;

  return element;
};
