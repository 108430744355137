import { AvatarUser } from 'components/AvatarUser';

export function PrimaryPanelUserAvatar() {
  return (
    <AvatarUser
      sx={{
        width: 94,
        height: 94,
      }}
    />
  );
}
