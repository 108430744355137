import { GuideAvatar } from 'components/Guide';
import { useRelationships, useUsers } from 'hooks';
import { getRecipients } from 'utils/getRelationshipRecipients';

interface PrimaryPanelOtherPartyAvatarProps {
  isGuide: boolean;
}

export function PrimaryPanelOtherPartyAvatar({
  isGuide,
}: PrimaryPanelOtherPartyAvatarProps) {
  const { users } = useUsers({ getSilently: false });
  const user = users().at(0);
  const userId = user?.id;

  const { relationships: getRelationships } = useRelationships({});
  const relationship = getRelationships();
  const activeRelationships = relationship.filter(
    (relationship) => !relationship.isConcluded,
  );
  const [otherPartyProfile] = getRecipients({
    role: isGuide ? 'guide' : 'trainee',
    relationship: activeRelationships.at(0),
    userId,
  });

  return (
    <GuideAvatar
      profilePicture={otherPartyProfile?.picture}
      userPicture={otherPartyProfile?.userPicture}
      width={94}
      height={94}
      sx={{ border: '1px solid white' }}
    />
  );
}
